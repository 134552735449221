.body-mx {
  .quote {
    .quote-inner {
      margin-right: -140px;

      @media only screen and (max-width: $width-lg) {
        width: 340px;
        margin-right: 0;
      }
      @media only screen and (max-width: $width-sm) {
        width: 290px;
        margin-left: 70px;
      }
    }

    .quote-author {
      color: #ccc;
    }
  }

  .intro-line {
    text-shadow: 0 0 10px #222, 0 0 10px #222, 0 0 7px #222;
    @media only screen and (max-width: $width-sm) {
      text-shadow: none;
    }
  }

  #btn-play {
    width: 220px;
  }
}

.body-it {
  .quote {
    .quote-inner {
      width: 275px;
      .quote-text {
        text-shadow: 0 0 5px #222, 0 0 3px #222;
      }
    }
  }
}

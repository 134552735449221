.body-de {
  .content {
    width: 100%;
  }

  .quote {
    width: 350px;
    margin-left: 62%;

    @media only screen and (max-width: $width-lg) {
      width: 300px;
      margin-left: 60%;
    }
    @media only screen and (max-width: $width-sm) {
      width: 260px;
      margin-right: 2%;
      margin-left: auto;
    }
    @media only screen and (max-width: $width-xs) {
      width: 225px;

      .quote-author {
        font-size: 20px;
      }
    }

    .quote-inner {
      width: auto;
    }
  }

  #btn-play {
    padding-top: 9px;

    @media only screen and (max-width: $width-lg) {
      padding-top: 4px;
    }
    @media only screen and (max-width: $width-sm) {
      padding-top: 2px;
    }
  }
}

.modal {
  padding: 0 !important;
  font-family: 'Lato', sans-serif !important;
  text-align: center;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  height: 100%;
  margin: .5rem;
}

.modal-content {
  border-radius: 0;
  margin-bottom: 30px;
  @media only screen and (min-width: $width-sm) {
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);
  }
}

.modal-header {
  padding: 0;
  border: 0;
}

.modal-close:active,
.modal-close {
  background-image: url("images/close.png");
  background-size: 32px;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  z-index: 9;
  @media print, only screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 144dpi), screen and (min-resolution: 144dppx) {
    background-image: url("images/close@2x.png");
  }
}

.modal-body {
  padding: 0;
  color: #000;

  label {
    color: #666;
    font-weight: normal;
  }
}

#modal-info .modal-content {
  padding-top: 42px;
}

#modal-info iframe,
#modal-join iframe {
  min-height: 700px;
}

#modal-video-selection {
  $width-md-custom: 700px;

  .modal-dialog {
    width: 1230px;
    @media only screen and (max-width: $width-lg-custom) {
      width: 660px;
    }
    @media only screen and (max-width: $width-sm) {
      width: 100%;
    }
  }
}


.modal-lg {
  @media (min-width: 992px) {
    max-width: 900px;
  }
}

$width-lg: 1200px; // Large Devices, Wide Screens
$width-md: 992px; // Medium Devices, Desktops
$width-sm: 768px; // Small Devices, Tablets
$width-xs: 480px; // Extra Small Devices, Phones

$color-pink: #fa015f;

@font-face {
  font-family: 'DINCond';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/DINCond-Bold.woff2') format('woff2'),
  url('fonts/DINCond-Bold.woff') format('woff'),
  url('fonts/DINCond-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('fonts/lato-v22-latin-300.woff2') format('woff2'),
       url('fonts/lato-v22-latin-300.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: local(''),
       url('fonts/lato-v22-latin-300italic.woff2') format('woff2'),
       url('fonts/lato-v22-latin-300italic.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('fonts/lato-v22-latin-regular.woff2') format('woff2'),
       url('fonts/lato-v22-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('fonts/lato-v22-latin-italic.woff2') format('woff2'),
       url('fonts/lato-v22-latin-italic.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('fonts/lato-v22-latin-700.woff2') format('woff2'),
       url('fonts/lato-v22-latin-700.woff') format('woff');
}

.modal-body {
  @font-face {
    font-family: 'DINCond';
    font-style: normal;
    font-weight: 700;
    src: url('fonts/DINCond-Bold.woff2') format('woff2'),
    url('fonts/DINCond-Bold.woff') format('woff'),
    url('fonts/DINCond-Bold.ttf') format('truetype');
  }

  .contentbox {
    padding-bottom: 50px;
    clear: both;
    color: #666;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: .02em;
  }

  .centered {
    margin: auto;
    text-align: center;
  }

  .infobox {
    width: 70%;
    margin: auto;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: .04em;
    line-height: 150%;
    text-align: center;
  }

  .joinbox {
    width: 70%;
    margin: 0 auto 100px;
    font-size: 18px;
    letter-spacing: .04em;
    line-height: 150%;
    text-align: left;
  }

  .title {
    margin: 20px 0 0;
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .08em;
  }

  hr {
    width: 40px;
    height: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    float: left;
    clear: both;
    border: 0 none;
    background-color: #000;
    color: #000;
  }

  hr.black {
    width: 40px;
    height: 2px;
    margin-top: 30px;
    margin-bottom: 30px;
    float: none;
    clear: both;
    border: 0 none;
    background-color: #000;
    color: #000;
  }

  ul {
    margin: 0 0 0 20px;
    padding: 0;
    font-size: 15px;
    list-style: none;
  }

  li {
    padding-left: 1em;
    text-indent: -.7em;
  }

  li::before {
    color: #fa015f;
    content: 'â€¢ ';
  }

  a,
  a:active,
  a:hover,
  a:link,
  a:visited {
    color: #fa015f !important;
  }

  a.btn-ianimal,
  a.btn-ianimal:hover,
  a.btn-ianimal:active,
  a.btn-ianimal:focus {
    display: inline-block;
    padding: 18px;
    border-radius: 3px;
    background-color: #fdec6c;
    color: #000;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 4px;
    text-decoration: none;
    text-transform: uppercase;
  }

  .btn-ianimal {
    width: inherit !important;
    height: inherit !important;
  }
}

.video-item .details .description {
  text-align: left;
}

#modal-videos .modal-dialog {
  @media only screen and (min-width: $width-lg-custom) {
    width: auto !important;
    max-width: inherit !important;
  }

  .modal-body .infobox {
    width: 100%;
  }
}

.video-item .buttons .text {
  font-size: 14px;
}


@import 'util/bootstrap-custom';

$width-lg-custom: 1300px;

@import 'partials/base';
@import 'partials/reset';
@import 'partials/style';
@import 'partials/style-de';
@import 'partials/style-uk';
@import 'partials/style-us';
@import 'partials/style-it';
@import 'partials/style-mx';
@import 'partials/style-es';
@import 'partials/modal';
@import 'partials/modal-body';
@import 'partials/videos';

.body-us {
  .quote {
    .quote-inner {
      width: 205px;
      margin-right: 100px;

      @media only screen and (max-width: $width-sm) {
        width: 160px;
        margin-right: 30%;
      }
      @media only screen and (max-width: $width-xs) {
        width: 150px;
        margin-right: 20px;
      }
    }
  }
}

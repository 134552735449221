.dropdown-toggle::after { content: none; }

.btn {
  font-size: 14px;
  line-height: 1.42;
}

h1 {
  line-height: 1.1;
}

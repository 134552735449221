.videos-container {
  max-width: 1230px;
  margin: 0 auto;
}

.videos-header {
  padding: 0 10px;
  color: #000;
  text-align: center;

  .title {
    margin-top: 34px;
    font-family: 'DINCond', sans-serif;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -1.3px;
    line-height: 1;
    text-transform: uppercase;
  }

  .separator {
    width: 50px;
    margin: 14px auto 12px;
    border-top: 2px solid #000;
  }

  .description {
    margin-bottom: 30px;
    font-size: 19px;
    letter-spacing: .3px;
  }
}

.video-item {
  height: 292px;
  margin: 7px auto 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  color: #000;

  @media only screen and (max-width: $width-lg-custom) {
    width: 100%;
    height: auto;
  }

  .presenter {
    width: 210px;
    height: 100%;
    float: left;
    background-color: #efefef;
    text-align: center;

    @media only screen and (max-width: $width-lg-custom) {
      display: none;
    }

    img {
      margin-top: 30px;
    }
  }

  .preview-image-md {
    display: none;

    @media only screen and (max-width: $width-lg-custom) {
      display: block;
      position: relative;
      width: 100%;
      max-width: 570px;
      margin: 0 auto;
      padding-top: 7px;

      img {
        width: 100%;
        height: auto;
      }

      .coming-soon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 258px;
        height: 71px;
        margin: auto;
        padding: 10px 26px 9px 24px;
        border: 2px solid;
        color: #fff;
        font-family: 'DINCond', sans-serif;
        font-size: 48px;
        font-weight: 700;
        letter-spacing: -2.1px;
        line-height: 1;
        text-transform: uppercase;
      }
    }
  }

  .details {
    position: relative;
    width: 448px;
    height: 100%;
    float: left;

    @media only screen and (max-width: $width-lg-custom) {
      width: 100%;
    }

    .description {
      margin: 21px 25px;
      font-size: 13px;
      letter-spacing: 1.8px;
      line-height: 1.3;
    }

    .buttons {
      position: absolute;
      bottom: 20px;
      width: 100%;
      text-align: center;

      @media only screen and (max-width: $width-lg-custom) {
        position: static;
        margin: 30px 0 20px;
      }
    }

    .buttons .btn-details {
      display: inline-block;
      width: 150px;
      height: 40px;
      margin: 0 5px;
      border-radius: 3px;
      background-color: #fdec6c;
      color: #000;
      text-decoration: none;

      .icon {
        margin: 8px 10px;
        float: left;
      }

      .btn-icon-separator {
        height: 29px;
        margin-top: 6px;
        float: left;
        border-left: 1px solid #000;
      }

      .text {
        margin: 11px 0 0 35px;
        float: left;
        font-weight: 700;
        letter-spacing: 2.3px;
      }
    }
  }

  .preview-image {
    position: relative;
    float: right;

    @media only screen and (max-width: $width-lg-custom) {
      display: none;
    }

    .triangle {
      position: absolute;
      top: 127px;
      left: -1px;
      width: 0;
      height: 0;
      border-top: 19px solid transparent;
      border-bottom: 19px solid transparent;
      border-left: 26px solid #fff;
    }

    .coming-soon {
      position: absolute;
      top: 110px;
      left: 138px;
      padding: 10px 26px 9px 24px;
      border: 2px solid;
      color: #fff;
      font-family: 'DINCond', sans-serif;
      font-size: 48px;
      font-weight: 700;
      letter-spacing: -2.1px;
      line-height: 1;
      text-transform: uppercase;
    }
  }
}

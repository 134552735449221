.body-es {
  .quote {
    .quote-inner {
      width: 235px;

      @media only screen and (max-width: $width-sm) {
        width: 180px;
        margin-right: 50px;
      }
      @media only screen and (max-width: $width-xs) {
        width: 170px;
        margin-right: 20px;
      }
    }
  }
}


html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif !important;
  min-height: 680px;

  &.main {
    color: #fff;
    background-image: url("images/background.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

h1 {
  margin: 0;
  font-size: 36px;

  @media only screen and (max-width: $width-lg) {
    font-size: 32px;
  }
  @media only screen and (max-width: $width-xs) {
    font-size: 28px;
  }
}

.logo-ianimal {
  position: absolute;
  top: 30px;
  right: 0;
  left: 0;
  text-align: center;

  @media only screen and (max-width: $width-sm) {
    position: static;;
    margin: 0 auto;
    padding-top: 30px;
  }

  img {
    width: 274px;
    height: 74px;
  }
}

.sidebar {
  position: absolute;
  top: 0;
  margin: 30px 0 0;
  z-index: 10;

  > ul {
    padding: 0;

    > li {
      margin: 0 0 3px;
      list-style-type: none;
    }
  }
}

.sidebar-left {
  left: 0;

  @media only screen and (max-width: $width-xs) {
    margin: 160px 0 0;

    img {
      width: 60px;
      height: auto;
    }
  }
}

.sidebar-right {
  right: 0;
}

.dropdown-lang {
  margin: 0 30px 0 0;

  a,
  a:hover,
  a:active,
  a:focus {
    font-size: 12px;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
  }

  > a,
  > a:hover,
  > a:active,
  > a:focus {
    display: block;
    width: 130px;
    height: 59px;
    padding: 20px 0 0 20px;
    border: 2px solid #fff;
    color: #fff;
  }

  .chevron-down {
    display: inline-block;
    position: relative;
    top: 5px;
    left: 6px;
    width: 5px;
    height: 5px;
    transform: rotate(135deg);
    border-width: 1px 1px 0 0;
    border-style: solid;
    vertical-align: top;
  }
}

.dropdown-lang-menu {
  width: 130px;
  min-width: 0;
  margin: 0;
  padding: 7px 0;
  border-top: 0;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  border-radius: 0;
  background-color: inherit;
  box-shadow: none;

  li {
    width: 108px;
    margin: 0;
    padding: 3px 0 3px 20px;
  }

  > li > a {
    &,
    &:hover,
    &:active,
    &:focus {
      padding: 3px 0;
      background-color: inherit;
      color: #fff;
    }
  }
}

.logo-animalequality {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0 15px 30px 0;
  z-index: 10;

  @media only screen and (max-width: $width-sm) {
    position: inherit;
    width: 149px;
    margin: 0 auto 15px;
  }
}

.content-wrapper {
  position: relative;
  height: 100%;

  @media only screen and (max-width: $width-sm) {
    position: static;;
    height: auto;
    margin-top: 50px;
  }
}

.content {
  position: absolute;
  right: 0;
  bottom: 30px;
  left: 0;
  width: 750px;
  margin: auto;
  text-align: center;

  @media only screen and (max-width: $width-sm) {
    position: inherit;
    width: 100%;
  }
}

.quote {
  margin: 0 19px 134px 0;
  text-align: left;

  @media only screen and (max-width: $width-lg) {
    margin: 0 0 130px;
  }
  @media only screen and (max-width: $width-sm) {
    margin: 0 0 25px;
  }
  @media only screen and (max-width: $width-xs) {
    margin: 0 5px 25px;
  }
}

.quote-inner {
  width: 380px;
  margin-left: auto;
  font-size: 20px;

  @media only screen and (max-width: $width-lg) {
    font-size: 18px;
  }
  @media only screen and (max-width: $width-sm) {
    width: 300px;
    font-size: 15px;
  }
  @media only screen and (max-width: $width-xs) {
    width: 275px;
    font-size: 14px;
  }
}

.quote-separator {
  width: 50px;
  border-top: 2px solid #fff;
}

.quote-text {
  margin: 15px 0;
  font-family: Lato;
  font-style: italic;
  letter-spacing: .8px;
  line-height: 25px;

  @media only screen and (max-width: $width-lg) {
    line-height: 28px;
  }
  @media only screen and (max-width: $width-xs) {
    line-height: 20px;
  }
}

.quote-author {
  margin-top: 20px;
  color: #000;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;

  small {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.2px;
  }
}

.intro-line {
  font-family: 'DINCond', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.intro-line-separator {
  width: 50px;
  margin: 25px auto 27px;
  border-top: 2px solid #fff;

  @media only screen and (max-width: $width-lg) {
    margin: 15px auto 17px;
  }
}


.main-buttons > .btn {
  margin: 0 10px;
  @media only screen and (max-width: $width-sm) {
    margin: 0 10px 10px;
  }
}

.btn-ianimal,
.btn-ianimal:hover,
.btn-ianimal:active,
.btn-ianimal:focus {
  width: 232px;
  height: 75px;
  padding: 18px 0 0;
  border-radius: 3px;
  background-color: #fdec6c;
  color: #000;
  font-weight: 900;
  letter-spacing: 4px;
  text-transform: uppercase;

  @media only screen and (max-width: $width-lg) {
    width: 200px;
    height: 65px;
    padding: 13px 0 0;
  }
  @media only screen and (max-width: $width-sm) {
    width: 175px;
    height: 55px;
    padding: 8px 0 0;
    font-size: 12px;
  }
}

.video-subtext {
  color: #666;
  font-weight: 400;
  letter-spacing: normal;;
  text-transform: none;
}

.video-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.text-placeholder {
  color: #999 !important;
}

.text-placeholder option {
  color: #555 !important;
}
